/**
 * @prettier
 */
import React from 'react';
import get from 'lodash/get';
import {graphql} from 'gatsby';
import AnimateWhenVisible from '../../components/AnimateWhenVisible';
import CTABlock from '../../components/CTABlock';
import CTAButton from '../../components/CTAButton';
import HeroBanner from '../../components/HeroBanner';
import Link from '../../components/Link';
import Page from '../../components/Page';
import * as UI from '../../components/UI/styles';

const Resources = ({data}) => {
    const page = get(data, 'page');

    return (
        <Page>
            <HeroBanner title={get(page, 'title')} text={get(page, 'pageHeading')}>
                <UI.Heading3 as="h2">Guides</UI.Heading3>

                <UI.LayoutContainer css={UI.flexAtMobile}>
                    {data.guides.edges.map((page, index) => (
                        <UI.LayoutItem
                            sizeAtMobile={6 / 12}
                            key={page.node.slug}
                            style={{marginBottom: '1.5rem'}}
                        >
                            <AnimateWhenVisible
                                delay={0.75 + 0.25 * (index + 1)}
                                style={{height: '100%'}}
                            >
                                <CTABlock to={`/resources/${page.node.slug}`}>
                                    {page.node.title}
                                </CTABlock>
                            </AnimateWhenVisible>

                            <UI.Spacer size="s" />
                        </UI.LayoutItem>
                    ))}
                </UI.LayoutContainer>
            </HeroBanner>
        </Page>
    );
};
export const query = graphql`
    query {
        page: contentfulPage(title: {eq: "Resources"}) {
            title
            pageHeading
            content {
                content
            }
        }
        guides: allContentfulPage(
            filter: {pageType: {eq: "Resource"}, category: {title: {eq: "Guides"}}}
            sort: {fields: [publishDate], order: DESC}
        ) {
            edges {
                node {
                    title
                    slug
                    pageType
                    tags
                    featuredPhoto {
                        fluid: sizes(maxWidth: 600) {
                            ...GatsbyContentfulSizes
                        }
                    }
                    updatedAt
                    publishDate
                    content {
                        content
                    }
                }
            }
        }
    }
`;

export default Resources;
